import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { ColorPicker } from 'material-ui-color';


function Setting(props) {
  // console.log(props);

  const handleRadioChange = (event) => {
    props.onChangeSource(props.index, event.target.value);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'url') {
      props.onChangeImageUrl(props.index, value);
      return;
    }
  };

  const onHandleColor = (e) => {
    console.log(e)
    const {hex} = e;
    props.onChangeColor(props.index, '#'+hex);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', border: 0, p: 1 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{props.text} Setting</FormLabel>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={props.source}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="color" control={<Radio />} label="Color" />
          <FormControlLabel value="ccktmc" control={<Radio />} label="CCKTMC" />
          <FormControlLabel value="url" control={<Radio />} label="Image URL" />
          <FormControlLabel value="local" control={<Radio />} label="Local Image(Need to re-upload after refresh)" />
        </RadioGroup>
      </FormControl>
      <Box>
        {props.source === 'color' ? <Box sx={{'text-align': 'left'}}>
          {/* <TextField
          // disabled={props.readonly}
          // error={props.error}
          // id={props.id}
          label='Color(Hex Color, Eg. 00FF00)'
          value={props.color}
          variant="standard"
        // onChange={props.onChange}
        /> */}
        <Box>Click color to edit!</Box>
        <ColorPicker value={props.color} onChange={onHandleColor} />
        </Box>: null}
        {props.source === 'url' ? <FormControl sx={{ width: '60ch' }}>
          <TextField
            // disabled={props.readonly}
            // error={props.error}
            id='url'
            label='Image URL'
            value={props.url}
            variant="standard"
            onChange={handleChange}
          />
        </FormControl> : null}
        {props.source === 'local' ? <input type="file" onChange={(e) => { props.onChangeLocalImage(props.index, URL.createObjectURL(e.target.files[0])) }} accept='.jpg, .jpeg, .png, .gif' /> : null}
      </Box>
    </Box>
  );
}

export default Setting;
