function sec2min(seconds) {
  // seconds: integer or text
  if (!Number.isInteger(seconds)) {
    seconds = Number.parseInt(seconds);
    if (isNaN(seconds)) {
      return 'ERROR';
    }
  }
  const min = pad(Math.floor(seconds / 60));
  const sec = pad(seconds % 60);

  return min+":"+sec;
}

function pad(num) {
  num = num.toString();
  while (num.length < 2) num = "0" + num;
  return num;
}

function min2sec(minText) {
  // minText: minutes text
  const arr = minText.split(':');
  if (arr.length !== 2) {
    return null;
  }
  if (!isNumeric(arr[0]) || !isNumeric(arr[1])) {
    return null;
  }
  let m,s;
  m = Number.parseInt(arr[0]);
  s = Number.parseInt(arr[1]);
  if (s > 60) {
    return null;
  }
  return m * 60 + s;
}

function isNumeric(val) {
  return /^\d{1,2}$/.test(val);
}

export { sec2min, min2sec };
