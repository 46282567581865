import Box from '@mui/material/Box';


function ColorImage(props) {
  const height = Number.parseInt(props.height);
  const width = Number.parseInt(props.width);

  if (props.color) {
    return (<Box sx={{ backgroundColor: props.color, height, width }} onClick={props.onClick} />);
  }
  return (<Box sx={{height, width}} onClick={props.onClick}><img src={props.image} height={height} alt='' /></Box>);
}

export default ColorImage;