import { TextField } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import ColorImage from './ColorImage';


const HEIGHT = 48;
const WIDTH = Math.floor(HEIGHT/9*16);

export default function TimeColor(props) {
  const getColorImage = () => {
    const { source, color, ccktmc, url, local, readonly } = props;
    const onClick = () => {
      if (!readonly) {
        props.onClick();
      }
    };

    if (source === 'ccktmc') {
      return <ColorImage image={ccktmc} height={HEIGHT} width={WIDTH} onClick={onClick} />
    } else if (source === 'url') {
      return <ColorImage image={url} height={HEIGHT} width={WIDTH} onClick={onClick} />
    } else if (source === 'local') {
      return <ColorImage image={local} height={HEIGHT} width={WIDTH} onClick={onClick} />
    } else {
      return <ColorImage color={color} height={HEIGHT} width={WIDTH} onClick={onClick} />
    }
  };

  return (
    // <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1 }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ width: '8ch' }}
      >
        <TextField
          disabled={props.readonly}
          error={props.error}
          id={props.id}
          label={props.text}
          value={props.time}
          variant="standard"
          onChange={props.onChange}
        />
      </Box>
      <Box sx={{ backgroundColor: '#FFFFFF', height: HEIGHT, width: WIDTH }}>
        {getColorImage()}
      </Box>
    </Box>
  )
}