import * as React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import TimeColor from './components/TimeColor';
import { sec2min, min2sec } from './utils/sec2min';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ColorImage from './components/ColorImage';
import Setting from './components/Setting';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';


const HEIGHT = 430;
const WIDTH = Math.floor(HEIGHT / 9 * 16);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DEFAULT_TIMER_DATA = [
  { id: 'sec0', text: 'No Light', time: '00:00', error: false, source: 'color', color: '#AAAAAA', ccktmc: 'CCKTMC-Timer.png', url: '', local: '' },
  { id: 'sec1', text: 'Green Light', time: '00:03', error: false, source: 'color', color: '#00FF00', ccktmc: 'CCKTMC-Timer-Green.png', url: '', local: '' },
  { id: 'sec2', text: 'Amber Light', time: '00:06', error: false, source: 'color', color: '#FFB700', ccktmc: 'CCKTMC-Timer-Yellow.png', url: '', local: '' },
  { id: 'sec3', text: 'Red Light', time: '00:09', error: false, source: 'color', color: '#FF0000', ccktmc: 'CCKTMC-Timer-Red.png', url: '', local: '' },
  { id: 'sec4', text: 'Timeout', time: '00:12', error: false },
];

function App() {
  const [seconds, setSeconds] = React.useState(0);
  const [counts, setCounts] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const [timerData, setTimerData] = React.useState([]);
  const timerSeconds = React.useRef([{ seconds: 0, color: '#AAAAAA' }]);
  const [openBar, setOpenBar] = React.useState(false);
  const [records, setRecords] = React.useState(JSON.parse(localStorage.getItem('records')) || '');
  const [window, setWindow] = React.useState('records');
  const [cookieAccepted, setCookieAccepted] = React.useState(JSON.parse(localStorage.getItem('cookieAccepted')) || false);
  const beep = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const clearAllData = () => {
    setSaveRecords('');
    setSaveTimerData(DEFAULT_TIMER_DATA);
  };

  const handleCookieAccepted = () => {
    setCookieAccepted(true);
    localStorage.setItem('cookieAccepted', JSON.stringify(true));
  };

  const handleCloseBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenBar(false);
  };

  const setSaveTimerData = (data) => {
    setTimerData(data);
    localStorage.setItem('timerData', JSON.stringify(data));
    typeof (window) === 'number' && window >= 0 && window < 4 && updateImageView(data[window]);
  }

  const refreshImageView = (index) => {
    updateImageView(timerData[index]);
  }

  const updateImageView = (data) => {
    // update big image view
    let { color, ccktmc, url, local, source } = data;
    if (source === 'ccktmc') {
      timerSeconds.current = [{ seconds: 0, image: ccktmc }]
    } else if (source === 'url') {
      timerSeconds.current = [{ seconds: 0, image: url }]
    } else if (source === 'local') {
      timerSeconds.current = [{ seconds: 0, image: local }]
    } else {
      timerSeconds.current = [{ seconds: 0, color }]
    }
  };

  const onChangeSource = (index, source) => {
    if (index < timerData.length) {
      const newData = timerData.slice();
      newData[index] = { ...timerData[index], source };
      setSaveTimerData(newData);
    }
  }

  const onChangeImageUrl = (index, url) => {
    if (index < timerData.length) {
      const newData = timerData.slice();
      newData[index] = { ...timerData[index], url };
      setSaveTimerData(newData);
    }
  }

  const onChangeLocalImage = (index, local) => {
    if (index < timerData.length) {
      const newData = timerData.slice();
      newData[index] = { ...timerData[index], local };
      setSaveTimerData(newData);
    }
  }

  const onChangeColor = (index, color) => {
    if (index < timerData.length) {
      const newData = timerData.slice();
      newData[index] = { ...timerData[index], color };
      setSaveTimerData(newData);
    }
  }

  const calcTimerSeconds = () => {
    // error: return null
    // return [{seconds, images}]
    let i;
    const data = [];
    for (i = 0; i < timerData.length; i++) {
      const { id, time, source, error } = timerData[i];
      if (error) {
        return null;
      }
      const seconds = min2sec(time);
      if (id === 'sec4') {
        // get amber-2 and red-3
        const color_images = [];
        color_images.push({ color: data[2].color, image: data[2].image });
        color_images.push({ color: data[3].color, image: data[3].image });
        data.push({ seconds, color_images });
      } else {
        let { color, ccktmc, url, local } = timerData[i];
        if (source === 'ccktmc') {
          data.push({ seconds, image: ccktmc });
        } else if (source === 'url') {
          data.push({ seconds, image: url });
        } else if (source === 'local') {
          data.push({ seconds, image: local });
        } else {
          data.push({ seconds, color });
        }
      }
    }
    return data;
  };

  const playBeep = () => {
    if (!isPlaying) {
      if (beep.current === null) {
        beep.current = new Audio("/timeout.mp3");
        beep.current.play();
      } else {
        beep.current.play();
      }
      setIsPlaying(true);
    }
  }

  const stopBeep = () => {
    if (beep.current) {
      beep.current.pause();
      beep.current = null;
      setIsPlaying(false);
    }
  }

  const pauseBeep = () => {
    if (beep.current && isPlaying) {
      beep.current.pause();
      setIsPlaying(false);
    }
  }

  const toggleTimer = () => {
    if (!isActive || counts) {
      const data = calcTimerSeconds();
      if (data != null) {
        timerSeconds.current = data;
        setIsActive(!isActive);
        setWindow('records');
      } else {
        // show error
        setOpenBar(true);
      }
    } else {
      setIsActive(!isActive);
    }
  }

  const setSaveRecords = (data) => {
    localStorage.setItem("records", JSON.stringify(data));
    setRecords(data);
  };

  const resetTimer = () => {
    stopBeep();
    if (seconds !== 0) {
      let data = records;
      if (records.length !== 0) {
        data = data + "\n";
      }
      setSaveRecords(data + sec2min(seconds));
    }
    setSeconds(0);
    setCounts(0);
    setIsActive(false);
  }

  const setPresetTimer = (green, amber, red, flash) => {
    const newData = [
      timerData[0],
      { ...timerData[1], time: green },
      { ...timerData[2], time: amber },
      { ...timerData[3], time: red },
      { ...timerData[4], time: flash },
    ];
    setSaveTimerData(newData);
  }

  React.useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setCounts((counts) => { setSeconds(Math.floor((counts + 1) / 2)); return counts + 1 });
      }, 500);
    } else if (seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem('timerData')) || DEFAULT_TIMER_DATA;
    setTimerData(data);
    updateImageView(data[0]);
  }, []);

  const handleChange = (e) => {
    // console.log(e.target);
    const { id, value } = e.target;
    if (id === 'sec0') {
      return;
    }

    if (id === 'records') {
      setSaveRecords(value);
      return;
    }

    const i = Number.parseInt(id.slice(-1));
    if (!isNaN(i) && i < timerData.length) {
      const newData = timerData.slice();
      newData[i] = { ...timerData[i], time: value, error: min2sec(value) === null }
      setSaveTimerData(newData);
    }
  };

  const getColorImage = () => {
    const data = timerSeconds.current;
    let i = 0;
    // Didn't check data.length here, must be > 1
    for (i = 0; i < data.length - 1; i++) {
      if ((seconds >= data[i].seconds) && (seconds < data[i + 1].seconds)) {
        break;
      }
    }

    if (i == data.length - 1) {
      if (isActive) {
        playBeep();
      } else {
        pauseBeep();
      }
    }
    
    let color, image;
    if (data[i].color_images) {
      ({ color, image } = data[i].color_images[counts % 2]);
    } else {
      ({ color, image } = data[i]);
    }
    if (color) {
      return <ColorImage color={color} height={HEIGHT} width={WIDTH} />
    }
    return <ColorImage image={image} height={HEIGHT} width={WIDTH} />
  }

  const noEdit = () => {
    return (isActive || counts) ? true : false;
  }

  return (
    <div className="App">
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, m: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 200, alignItems: 'center' }}>
          {timerData.map((value, index) => {
            return <TimeColor {...value} key={value.id} onChange={handleChange} readonly={noEdit()} onClick={() => { setWindow(index); refreshImageView(index); }} />;
          })}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Button variant="contained" onClick={toggleTimer}
              color={isActive ? 'error' : counts ? 'secondary' : 'primary'}
            >
              {isActive ? 'Pause' : counts ? 'Resume' : 'Start'}
            </Button>
            <Button variant="contained" color="grey" onClick={resetTimer}>Reset</Button>
          </Box>
          <Box component="div"
            sx={{ fontsize: 16, mt: 1 }}>
            {sec2min(seconds)}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& > *': {
                m: 1,
              },
            }}
          >
            <ButtonGroup variant="outlined">
              <Button disabled={noEdit()} onClick={() => setPresetTimer('01:00', '01:30', '02:00', '02:30')}>1-2m</Button>
              <Button disabled={noEdit()} onClick={() => setPresetTimer('02:00', '02:30', '03:00', '03:30')}>2-3m</Button>
              <Button disabled={noEdit()} onClick={() => setPresetTimer('04:00', '05:00', '06:00', '06:30')}>4-6m</Button>
            </ButtonGroup>
            <ButtonGroup variant="outlined">
              <Button disabled={noEdit()} onClick={() => setPresetTimer('05:00', '06:00', '07:00', '07:30')}>5-7m</Button>
              <Button disabled={noEdit()} onClick={() => setPresetTimer('00:03', '00:06', '00:09', '00:12')}>demo</Button>
            </ButtonGroup>
            <ButtonGroup variant="outlined">
              {window === 'records' ? <ArticleIcon /> : <ArticleOutlinedIcon onClick={() => setWindow('records')} />}
              {window === 'setting' ? <SettingsIcon /> : <SettingsOutlinedIcon onClick={() => setWindow('setting')} />}
              {window === 'info' ? <InfoIcon /> : <InfoOutlinedIcon onClick={() => setWindow('info')} />}
            </ButtonGroup>
          </Box>
          <Box>
            <Box component="h2">TM Timer</Box>
            <p>CX &copy;2022, v1.02</p>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', m: 1, p: 1 }}>
          <Box>

            {/* <img src={getSourceIndex()} height="430" alt='' /> */}
            {getColorImage()}
          </Box>
          <Box sx={{ mt: 2 }}>
            {window === 'records' ? <Box>
              <FormControl sx={{ width: '30ch' }}>
                <TextField
                  id="records"
                  label="Records"
                  multiline
                  rows={7}
                  value={records}
                  onChange={handleChange}
                />
              </FormControl>
              <p>Above are all timer records, record when you hit <b>RESET</b> button.</p>
            </Box> : null}
            {window === 'info' ?
              <Box sx={{ textAlign: 'left', ml: 3 }}>
                <p>This is a timer for TM.</p>
                <p>It works best with OBS.</p>
                <br />
                <p>Welcome to check our club web <Link href="https://www.ccktmc.sg" target='_blank' rel='noopener'>https://www.ccktmc.sg</Link></p>
                <p>To sumbit your feedback or request new features, click <Link href="https://docs.google.com/forms/d/e/1FAIpQLSe5bbB0Neow7YmFXqSGmnpOExbDNMiuixqNCvSBg2mDEId5eg/viewform" target='_blank' rel='noopener'>here</Link>.</p>
                <p><b>Disclaimer</b>: Please follow your local rules to use this website.</p>
              </Box> : null}
            {window === 'setting' ?
              <Box sx={{ textAlign: 'left', ml: 3 }}>
                <p>Click each color/picture to set.</p>
                <p>After setting, please use DEMO to test before use.</p>
                <p>Use 16:9 images to get better view.</p>
                <p>Setting is not allowed after timer started.</p>
                <p><b>ALL DATA are saved in your local computer!</b></p>
                <br />
                <Box sx={{ mt: 1 }}>
                  <Button disabled={noEdit()} variant="contained" color="error" onClick={clearAllData}>Clear All Data</Button>
                  <Box component='span' sx={{ml: 2}}>Click CLEAR ALL DATA will <b>clear</b> saved data and records.</Box>
                </Box>
              </Box> : null}
          </Box>
          {typeof (window) === 'number' && window >= 0 && window < 4 && <Setting {...timerData[window]} index={window} onChangeSource={onChangeSource} onChangeImageUrl={onChangeImageUrl} onChangeLocalImage={onChangeLocalImage} onChangeColor={onChangeColor} />}
        </Box>
      </Box>
      <Snackbar open={openBar} autoHideDuration={5000} onClose={handleCloseBar}>
        <Alert onClose={handleCloseBar} severity="warning" sx={{ width: '100%' }}>
          Time setting is not correct. Please use MM:SS
        </Alert>
      </Snackbar>
      <Dialog onClose={() => { }} open={!cookieAccepted}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, }}>
          <Box component="span" sx={{ mb: 2 }}>
            This site uses cookies to offer you a better browsing experience.
          </Box>
          <Button variant='contained' onClick={handleCookieAccepted}>Accept</Button>
        </Box>
      </Dialog>
    </div>
  );
}


export default App;
